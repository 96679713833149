<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <v-card>
          <v-card-title>
            {{ $t('overview.livePerformances.subtitle.now') }}
          </v-card-title>
          <v-data-table :headers="headers" :options="tableOptions" :items="performancesNow" :loading="loading" hide-default-footer>
            <template #[`item.title`]="{ item }">
              {{ item.title }}
            </template>
            <template #[`item.preparation`]="{ item }">
              <span :style="'color:' + getColor(item.preparation, 15 * 60 * 1000, 20 * 60 * 1000)">
                {{ secondToHHmm(item.preparation) }}
              </span>
            </template>
            <template #[`item.waiting`]="{ item }">
              <span :style="'color:' + getColor(item.lateDelay, 10 * 60 * 1000, 15 * 60 * 1000)">
                {{ secondToHHmm(item.waiting) }}
              </span>
            </template>
            <template #[`item.delivery`]="{ item }">
              <span :style="'color:' + getColor(item.lateDelay, 25 * 60 * 1000, 35 * 60 * 1000)">
                {{ secondToHHmm(item.delivery) }}
              </span>
            </template>
            <template #[`item.total`]="{ item }">
              <span :style="'color:' + getColor(item.lateDelay, 40 * 60 * 1000, 60 * 60 * 1000)">
                {{ secondToHHmm(item.total) }}
              </span>
            </template>
            <template #[`item.lateDelay`]="{ item }">
              <span :style="'color:' + getColor(item.lateDelay, 0, 10 * 60 * 1000)">
                {{ getSign(item) }}{{ secondToHHmm(item.lateDelay) }}
              </span>
            </template>
            <template #[`item.lateCount`]="{ item }">
              <span :style="'color:' + getColor(item.lateCount, 1, 5 )">
                {{ item.lateCount }}
              </span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" lg="12">
        <v-card>
          <v-card-title>
            {{ $t('overview.livePerformances.subtitle.today') }}
          </v-card-title>
          <v-data-table :headers="headers" :options="tableOptions" :items="performancesToday" :loading="loading" hide-default-footer>
            <template #[`item.title`]="{ item }">
              {{ item.title }}
            </template>
            <template #[`item.preparation`]="{ item }">
              <span :style="'color:' + getColor(item.preparation, 15 * 60 * 1000, 20 * 60 * 1000)">
                {{ secondToHHmm(item.preparation) }}
              </span>
            </template>
            <template #[`item.waiting`]="{ item }">
              <span :style="'color:' + getColor(item.lateDelay, 10 * 60 * 1000, 15 * 60 * 1000)">
                {{ secondToHHmm(item.waiting) }}
              </span>
            </template>
            <template #[`item.delivery`]="{ item }">
              <span :style="'color:' + getColor(item.lateDelay, 25 * 60 * 1000, 35 * 60 * 1000)">
                {{ secondToHHmm(item.delivery) }}
              </span>
            </template>
            <template #[`item.total`]="{ item }">
              <span :style="'color:' + getColor(item.lateDelay, 40 * 60 * 1000, 60 * 60 * 1000)">
                {{ secondToHHmm(item.total) }}
              </span>
            </template>
            <template #[`item.lateDelay`]="{ item }">
              <span :style="'color:' + getColor(item.lateDelay, 0, 10 * 60 * 1000)">
                {{ getSign(item) }}{{ secondToHHmm(item.lateDelay) }}
              </span>
            </template>
            <template #[`item.lateCount`]="{ item }">
              <span :style="'color:' + getColor(item.lateCount, 1, 5 )">
                {{ item.lateCount }}
              </span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { secondToHHmm } from '@/util/DateUtil';

  export default {
    name: 'LivePerformancesOverview',
    props: {
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        loading: false,
        headers: [{
          text: '',
          align: 'center',
          filterable: false,
          sortable: false,
          value: 'title'
        }, {
          text: this.$i18n.t('overview.livePerformances.header.preparation'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'preparation'
        }, {
          text: this.$i18n.t('overview.livePerformances.header.waiting'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'waiting'
        }, {
          text: this.$i18n.t('overview.livePerformances.header.delivery'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'delivery'
        }, {
          text: this.$i18n.t('overview.livePerformances.header.total'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'total'
        }, {
          text: this.$i18n.t('overview.livePerformances.header.lateDelay'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'lateDelay'
        }, {
          text: this.$i18n.t('overview.livePerformances.header.lateCount'),
          align: 'center',
          filterable: false,
          sortable: true,
          value: 'lateCount'
        }],
        tableOptions: {
          page: 1,
          itemsPerPage: -1,
          sortBy: ['title'],
          sortDesc: [false],
          groupBy: [],
          groupDesc: [],
          multiSort: false,
          mustSort: true
        },
        performancesNow: [],
        performancesToday: []
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    mounted: async function () {
      await this.refreshData();
    },
    methods: {
      secondToHHmm: secondToHHmm,
      getColor (value, orangeThreshold, redThreshold) {
        if (value === undefined) {
          return 'black';
        }

        if (value < orangeThreshold) {
          return 'green';
        }

        if (value < redThreshold) {
          return 'orange';
        }

        return 'red';
      },
      getSign (item) {
        return item.lateDelay < 0 ? '-' : '';
      },
      parsePerformances (data) {
        const performances = [];
        if (typeof data !== 'object') {
          return performances;
        }

        Object.keys(data).sort((id1, id2) => {
          if (data[id1].name < data[id2].name) {
            return -1;
          }
          if (data[id1].name > data[id2].name) {
            return 1;
          }

          return 0;
        }).forEach((locationId) => {
          if (data[locationId].delays !== undefined && Object.keys(data[locationId].delays).length > 0) {
            performances.push({
              title: data[locationId].name,
              preparation: data[locationId].delays?.preparation,
              waiting: data[locationId].delays?.waiting,
              delivery: data[locationId].delays?.delivery,
              total: data[locationId].delays?.total,
              lateDelay: data[locationId].late?.delay,
              lateCount: data[locationId].late?.count
            });
          }
        });

        return performances;
      },
      async refreshData () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'analytics/locations/performances', { timeout: 60000 });
          if (response.data !== undefined) {
            this.performancesNow = this.parsePerformances(response.data.now);
            this.performancesToday = this.parsePerformances(response.data.today);
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('overview.error'));
        }
        this.loading = false;
      }
    }
  };
</script>
